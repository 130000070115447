/*LAYOUT WEB*/
.layout {
  height: 100vh;
  overflow-y: hidden; 

  background-color: #000000;
  background-image: url('../resources/images/bg-anvic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: calc(10px + 1vmin);
  text-align: center;
  color: #ffffff;

}

.icon-anvic{
  width: 45px;
  height: 45px;

  margin-bottom: -15px;
}

.title{
  font-size: 40px;
  font-weight: 700;

  letter-spacing: 1.8px;
  margin-bottom: 10px;
}

.subtitle{
  font-size: 16px;
  line-height: 10px;
}

.links{
  color: #ffffff;
  font-weight: 600;
}

.link {
  color: #fff; 
  letter-spacing: 0.3;
}

/*LAYOUT RESPONSIVE*/
@include responsive(1050px) {
  .layout{

  }
}

@include responsive(991px) {
  .layout{
    
  }
}